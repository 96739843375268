<template>
    <div>
        <b-row v-if="state === 'initializing' || state === 'loading'">
            <b-col class="text-center">
                <b-spinner large></b-spinner>
            </b-col>
        </b-row>

        <b-row class="mb-4" v-if="state !== 'initializing'">
            <b-col cols="8">
                <baseTables v-if="state === 'show'" :totalsData="selectedData"/>
            </b-col>
            <b-col cols="4" v-if="state === 'show' || state === 'showDateSelection'">
                <b-card>
                    <b-row>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedTimesheet.weekEndingDate | dateFilter" 
                            :labelName="'Week ending:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedTimesheet.status" 
                            :labelName="'Status:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedTimesheet.submitDate | dateTimeFilter" 
                            :labelName="'Submitted on:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedTimesheet.manager" 
                            :labelName="'Manager:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedTimesheet.notes" 
                            :labelName="'Comment:'" :labelRight="false"  /></b-col>
                    </b-row>
                    <b-row v-if="selectedTimesheet.status !== 'New'">
                        <b-col>
                            <b-button block variant="primary" @click="doDownloadTimesheet()">
                                Download</b-button>
                        </b-col>
                    </b-row>

                </b-card>

                <subtotalTables v-if="state === 'show'" class="mt-3" :totalsData="selectedData.totals" />
                
            </b-col>
        </b-row>
    </div>
</template>

<script>
import baseTables from '@/components/submitTimeSheetBaseTables.vue'
import subtotalTables from '@/components/submitTimeSheetSubTotalTables.vue'
import { mapActions, mapMutations, mapState } from 'vuex'


export default {
  components:{
    baseTables,
    subtotalTables
  },
  data: () => ({
    state: 'initializing',
    timeSelected: null,
    weekEndingLists: [],
    selectedData: {totals: []},
    
    tableDataSubTotal: {
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
                label: 'Day',
                key: 'description',
                sortable: true,
                tdClass: ''
            },
            {
                label: 'Hours',
                key: 'normaltime',
                sortable: true,
                tdClass: ''
            },
            {
                label: 'Overtime',
                key: 'overtime',
                sortable: true,
                tdClass: ''
            }
        ]
    }
  }),
  created(){

    if (this.selectedTimesheet === null || this.selectedTimesheet === undefined) {
        this.$router.back()
    }
    
    this.setBreadcrumb([
        {
            text: 'Submit Timesheet'
        },
        {
            text: 'View Submission',
            active: true
        }
    ])

    //console.log(this.selectedTimesheet)
    
    this.getSubmissionDetails()
    .then((res) => {
        //console.log(res)
        this.selectedData = res.data
        this.state = 'show'
    })
      
  },
  methods: {
    ...mapActions(['submitTimesheet', 'getSubmissionDetails', 'downloadWeeklySubmissionReport']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    doSubmitTimesheet(){
        this.state = 'loading'
        this.$store.commit('setGetForSubmissionRequest', this.timeSelected.key.substr(0,this.timeSelected.key.indexOf("T")))
        this.submitTimesheet()
        .then((res) => {
            //console.log(res)
            this.selectedData = res.data
            this.selectedData= {totals: []}
            this.timeSelected = null
            this.state = 'show'
        })
    },
    
    doDownloadTimesheet(){
        this.state = 'loading'
        this.downloadWeeklySubmissionReport()
        .then((res) => {
            //console.log(res)
            const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
            const downloadLink = document.createElement("a");

            downloadLink.href = linkSource;
            downloadLink.download = res.data.fileName;
            downloadLink.click();
            this.state = 'show'
        })
    },
  },
  computed: {
    ...mapState(['selectedTimesheet'])
  }
    
}
</script>